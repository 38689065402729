<template>
	<div>
		<v-row class="fixed">
			<v-col class="text-center">
				<v-avatar size="92">
					<img src="@/assets/images/avatar.jpg" alt="Andrei Sarbu" />
				</v-avatar>
			</v-col>
		</v-row>
		<v-timeline class="pt-0" clipped :dense="isMobile">
			<v-timeline-item
				v-for="(item, index) in timelineItems"
				:color="item.color"
				:key="index"
				small
			>
				<template v-slot:opposite>
					<span
						:color="item.color"
						:style="`color: ${item.color}`"
						class="headline font-weight-bold"
						v-text="item.year"
					></span>
				</template>
				<card :item="item" />
			</v-timeline-item>
		</v-timeline>
		<!-- <card
			v-else
			v-for="(item, index) in timelineItems"
			:key="index"
			:item="item"
			class="mb-5"
		></card> -->
	</div>
</template>

<script>
import Card from './Card';
export default {
	components: {
		Card,
	},
	data() {
		return {
			timelineItems: [
				{
					color: '#7fd6f6',
					jobTitle: 'Team Lead',
					employe: 'Verifone (former 2Checkout)',
					location: {
						country: 'Romania',
						city: 'Bucharest',
					},
					period: '1 year, 6 months',
					year: 'Sept. 2020 - present',
				},
				{
					color: '#3474ff',
					jobTitle: 'Team Lead',
					employe: '2Checkout (former Avangate)',
					location: {
						country: 'Romania',
						city: 'Bucharest',
					},
					period: '1 year',
					year: 'Aug. 2019 - Aug. 2020',
				},
				{
					color: '#3474ff',
					jobTitle: 'Team Lead',
					employe: 'Avangate',
					location: {
						country: 'Romania',
						city: 'Bucharest',
					},
					period: '8 months',
					year: 'Jan. 2019 - Aug. 2019',
				},
				{
					color: '#3474ff',
					jobTitle: 'Frontend Developer',
					employe: 'Avangate',
					location: {
						country: 'Romania',
						city: 'Bucharest',
					},
					period: '1 year, 6 months',
					year: 'Jun. 2017 - Dec. 2018',
				},
				{
					color: '#4CAF50',
					jobTitle: 'Frontend Developer',
					employe: 'Ultragrup',
					location: {
						country: 'Romania',
						city: 'Vaslui',
					},
					period: '5 years, 6 months',
					year: 'Jan. 2012 - Jun. 2017',
				},
				{
					color: '#2196F3',
					jobTitle: 'Highschool',
					location: {
						country: 'Romania',
						city: 'Vaslui',
					},
					period: '4 years',
					year: '2002 - 2006',
				},
				{
					color: '#F44336',
					jobTitle: 'I was born',
					location: {
						country: 'Romania',
						city: 'Vaslui',
					},
					period: '0 years',
					year: '1987',
				},
			],
			isMobile: false,
		};
	},
	mounted() {
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},

	beforeDestroy() {
		// Unregister the event listener before destroying this Vue instance
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		testIfIsMobile() {
			if (
				/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
					navigator.userAgent
				)
			) {
				return true;
			} else {
				return false;
			}
		},
		onResize() {
			if (this.testIfIsMobile() || window.innerWidth <= 768) {
				this.isMobile = true;
			}
			else {
				this.isMobile = false;
			}
			console.log('window has been resized', this.isMobile);
		},
	},
};
</script>
