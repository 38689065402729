<template>
	<v-card class="elevation-2">
		<v-card-title class="title" :style="`color: ${item.color}`">
			<span>{{ item.jobTitle }}</span>
			<span v-if="item.employe">, {{ item .employe }}</span>
		</v-card-title>
		<v-card-text class="white text--primary">
			<div class="pb-3">
				<span class="pr-2">
					<v-icon :style="`color: ${item.color}`">
						mdi-map-marker
					</v-icon>
					{{ item.location.city }},
					{{ item.location.country }}
				</span>
				<span>
					<v-icon :style="`color: ${item.color}`">
						mdi-calendar
					</v-icon>
					{{ item.period }}
				</span>
			</div>

			Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit
			deserunt voluptas, distinctio exercitationem numquam cum quibusdam
			mollitia totam nisi facilis excepturi necessitatibus natus ut
			accusamus consectetur hic maxime eveniet nostrum.
		</v-card-text>
	</v-card>
</template>

<script>
export default {
    props: {
        item: {
            required: true,
            default: () => {
                return {};
            }
        }
    }
}
</script>

<style scoped>
@media only screen and (max-width: 600px) {
    .v-application .headline {
        font-size: 1rem !important;
    }
}
</style>