<template>
	<v-app>
		<!-- Sizes your content based upon application components -->
		<v-main>
			<!-- Provides the application the proper gutter -->
			<!-- If using vue-router -->
			<!-- <router-view></router-view> -->
			<!-- <span class="spacer">spacer</span> -->
			<v-container class="pb-0">
				<HomePage></HomePage>
			</v-container>
			<!-- <span class="spacer">spacer</span> -->
		</v-main>
	</v-app>
</template>

<script>
import HomePage from './components/HomePage.vue';

export default {
	name: 'App',
	components: {
		HomePage,
	},
	metaInfo: {
		title: 'Andrei Sarbu',
		titleTemplate: '%s | Frontend Developer',
		meta: [
			{ charset: 'utf-8' },
			{ name: 'description', content: 'gator' },
			{
				name: 'viewport',
				content: 'width=device-width, initial-scale=1, maximum-scale=1',
			},
		],
	},
};
</script>

<style lang="scss">
.fixed {
	position: fixed;
    width: 100%;
    top: 0;
	left: 12px;
    background-color: white;
    z-index: 100;
	box-shadow: 0px 0px 7px 0px black;
}

.container {
	padding-top: 130px;
}
</style>